import React from 'react';

const HomePage = () => {
  return (
    <div>
      <h1>Добро пожаловать в админ-панель</h1>
      {/* Дополнительный контент */}
    </div>
  );
};

export default HomePage;
