import React, { useState } from 'react';
import { Container, Typography, TextField, Button, FormControl, InputLabel, Input, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const LoginPage = () => {
  const [loginData, setLoginData] = useState({
    username: '',
    password: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setLoginData({ ...loginData, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setLoginData({ ...loginData, showPassword: !loginData.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Здесь вы можете реализовать логику отправки данных для авторизации
    console.log(loginData);
  };

  return (
    <Container maxWidth="xs">
      <Typography variant="h4" component="h1" gutterBottom>
        Авторизация
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Логин или Email"
          margin="normal"
          value={loginData.username}
          onChange={handleChange('username')}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel htmlFor="password">Пароль</InputLabel>
          <Input
            id="password"
            type={loginData.showPassword ? 'text' : 'password'}
            value={loginData.password}
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {loginData.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          style={{ marginTop: '20px' }}
        >
          Войти
        </Button>
      </form>
    </Container>
  );
};

export default LoginPage;
