import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import CreateUserPage from '../pages/CreateUserPage'
import CreateProductPage from '../pages/CreateProductPage'
import UserList from '../components/UserList';
import UserProfile from '../components/UserProfile';
import ProductList from '../components/ProductList';
import ProductProfile from '../components/ProductProfile';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import LoginPage from '../components/LoginPage';
import './App.css'; // Путь к вашему CSS файлу

const App = () => {
  return (
    <Router>
      <div className="app-container">
        <Header />
        <div className="content-area">
          <Sidebar />
          <div className="main-content">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="CreateUserPage" element={<CreateUserPage />} />
              <Route path="CreateProductPage" element={<CreateProductPage />} />
              <Route path="/users" element={<UserList users={[]} />} /> {/* Предполагается, что вы передадите массив пользователей */}
              <Route path="/userprofile/:id" element={<UserProfile user={{}} />} /> {/* Предполагается, что вы передадите объект пользователя */}
              <Route path="/products" element={<ProductList products={[]} />} /> {/* Предполагается, что вы передадите массив товаров */}
              <Route path="/productprofile/:id" element={<ProductProfile product={{}} />} /> {/* Предполагается, что вы передадите объект товара */}
              <Route path="LoginPage" element={<LoginPage />} />
              {/* Добавьте другие маршруты по мере необходимости */}
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default App;
